import tw from 'twin.macro';
import { Text } from 'styles';
import { DetailedWrapper, Detailed } from './styles';
import { IMembershipProps } from './MembershipComponent';
import { useContactService } from 'services';

const DetailedTitle = tw(Text.Medium)`
  col-span-1 text-sm md:text-sm lg:text-md text-secondaryMidGrey mb-0 md:mb-0.5
`;

const DetailedContent = tw(Text.XLarge)`
  col-span-2 text-md md:text-lg lg:text-xl text-white
`;

const DetailedSubContent = tw(Text.XSmall)`
  text-neutralGrey mt-0.5
`;

export const MembershipDetailCell: React.FC<IMembershipProps> = ({
  isDetail = false,
}) => {
  const contactData = useContactService((s) => s.myContact.data);
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const membership = myMembershipsData?.[0];
  const cashOnCardBalance = contactData?.cashOnCardBalance?.toFixed(2);
  return (
    <DetailedWrapper isBorder={!isDetail}>
      <Detailed>
        <DetailedTitle>Member No.</DetailedTitle>
        <DetailedContent>{contactData?.customerNumber}</DetailedContent>
      </Detailed>
      <Detailed>
        <DetailedTitle>Membership</DetailedTitle>
        <DetailedContent>{membership?.memberType}</DetailedContent>
      </Detailed>
      <Detailed>
        <DetailedTitle>Status</DetailedTitle>
        <DetailedContent>{membership?.status}</DetailedContent>
      </Detailed>
      <Detailed>
        <DetailedTitle>Cash-on-card</DetailedTitle>
        <DetailedContent>
          {cashOnCardBalance ? `$${cashOnCardBalance}` : '-'}
        </DetailedContent>
        <DetailedSubContent>*Updated every 24 hours</DetailedSubContent>
      </Detailed>
    </DetailedWrapper>
  );
};

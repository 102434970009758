import { Text } from 'styles';
import { deviceSize } from 'components/shared/types';
import { useMediaQuery } from 'react-responsive';
import { DetailRefund } from 'pages/transactionhistory';
import { Divider } from 'components';
import DownloadPDFButton from './DownloadPDFButton';
import { dateWithFullMonthName } from 'helpers';
import {
  HeaderWrapper,
  HeaderLeftWrapper,
  TransactionNumberWrapper,
  TransactionNumber,
  TransactionDate,
  DescriptionWrapper,
  DescriptionColumn,
  QuantityColumn,
  CashTotalColumn,
  ExtraDetailsWrapper,
  PriceName,
  PriceValue,
  MemberNoColumn,
  DescriptionRow,
  Label,
  Value,
  MobileItemsWrapper,
  MobileItem,
  MobileItemRow,
} from './TransactionDetailsStyle';
import { InvoiceType } from 'configs/constants';
import { TransactionDetailType } from 'type';
import { useContactService } from 'services';

interface ITransactionDetailsData {
  data: TransactionDetailType;
}

function formatCurrency(value?: number | string | null) {
  return typeof value === 'number'
    ? `$${value.toFixed(2)}`
    : value
    ? value
    : 'N/A';
}

const TransactionDetailsData: React.FC<ITransactionDetailsData> = ({
  data: { number, date, items, price, type },
}) => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });
  const myContactData = useContactService((s) => s.myContact.data);

  const responsiveHeader = isMobile ? Text.H5 : isTablet ? Text.H3 : Text.H2;

  const responsiveText = isMobile
    ? Text.Small
    : isTablet
    ? Text.Normal
    : Text.Large;

  const responsiveTextBold = isMobile
    ? Text.SmallBold
    : isTablet
    ? Text.NormalBold
    : Text.LargeBold;

  return (
    <>
      <HeaderWrapper>
        <HeaderLeftWrapper>
          <TransactionNumberWrapper>
            <TransactionNumber as={responsiveHeader}>
              Ref. Number: {number}
            </TransactionNumber>
          </TransactionNumberWrapper>
          <TransactionDate as={responsiveText} color="#51504D">
            Date: {dateWithFullMonthName(date)}
            {type === InvoiceType.RETURN && <DetailRefund>REFUND</DetailRefund>}
          </TransactionDate>
        </HeaderLeftWrapper>
        <DownloadPDFButton hide={isTablet} myMembership={myContactData} />
      </HeaderWrapper>

      <Divider marginVertical={isMobile ? '1.75rem' : '3rem'} />

      <DescriptionWrapper variant="desktop">
        <DescriptionRow variant="header">
          <MemberNoColumn as={responsiveTextBold} variant="header">
            Member No.
          </MemberNoColumn>
          <DescriptionColumn as={responsiveTextBold} variant="header">
            Description
          </DescriptionColumn>
          <QuantityColumn as={responsiveTextBold} variant="header">
            Quantity
          </QuantityColumn>
          <CashTotalColumn as={responsiveTextBold} variant="header">
            Price
          </CashTotalColumn>
        </DescriptionRow>

        {items.map(({ id, name, quantity, cashTotal }) => (
          <DescriptionRow key={id} variant="row">
            <MemberNoColumn as={responsiveText} variant="row">
              {myContactData?.customerNumber}
            </MemberNoColumn>
            <DescriptionColumn as={responsiveText} variant="row">
              {name}
            </DescriptionColumn>
            <QuantityColumn as={responsiveText} variant="row">
              {quantity}
            </QuantityColumn>
            <CashTotalColumn as={responsiveText} variant="row">
              {`$${cashTotal.toFixed(2)}`}
            </CashTotalColumn>
          </DescriptionRow>
        ))}
      </DescriptionWrapper>

      <DescriptionWrapper variant="mobile">
        <MobileItemsWrapper>
          {items.map(({ id, name, quantity, cashTotal }) => (
            <MobileItem key={id}>
              <MobileItemRow>
                <Label as={responsiveText}>Member No.</Label>
                <Value as={responsiveText}>{myContactData?.customerNumber}</Value>
              </MobileItemRow>
              <MobileItemRow>
                <Label as={responsiveText}>Description</Label>
                <Value as={responsiveText}>{name}</Value>
              </MobileItemRow>
              <MobileItemRow>
                <Label as={responsiveText}>Quantity</Label>
                <Value as={responsiveText}>{quantity}</Value>
              </MobileItemRow>
              <MobileItemRow>
                <Label as={responsiveText}>Price</Label>
                <Value as={responsiveText}>{`$${cashTotal.toFixed(2)}`}</Value>
              </MobileItemRow>
            </MobileItem>
          ))}
        </MobileItemsWrapper>
      </DescriptionWrapper>

      <Divider height="3px" marginVertical={isMobile ? '2rem' : '3rem'} />

      <ExtraDetailsWrapper>
        <PriceName as={isTablet ? Text.NormalBold : Text.LargeBold}>
          Sub Total
        </PriceName>
        <PriceValue as={isTablet ? Text.Normal : Text.Large}>
          {formatCurrency(price?.subTotal)}
        </PriceValue>
      </ExtraDetailsWrapper>
      <Divider opacity="0.5" marginVertical={isMobile ? '1.2rem' : '1.5rem'} />
      <ExtraDetailsWrapper>
        <PriceName as={isTablet ? Text.NormalBold : Text.LargeBold}>
          Shipping
        </PriceName>
        <PriceValue as={isTablet ? Text.Normal : Text.Large}>
          {formatCurrency(price?.shipping)}
        </PriceValue>
      </ExtraDetailsWrapper>
      <Divider opacity="0.5" marginVertical={isMobile ? '1.2rem' : '1.5rem'} />
      <ExtraDetailsWrapper>
        <PriceName as={isTablet ? Text.NormalBold : Text.LargeBold}>
          GST
        </PriceName>
        <PriceValue as={isTablet ? Text.Normal : Text.Large}>
          {formatCurrency(price?.gst)}
        </PriceValue>
      </ExtraDetailsWrapper>
      <Divider opacity="0.5" marginVertical={isMobile ? '1.2rem' : '1.5rem'} />
      <ExtraDetailsWrapper>
        <PriceName as={isTablet ? Text.NormalBold : Text.LargeBold}>
          Total
        </PriceName>
        <PriceValue as={isTablet ? Text.Normal : Text.Large}>
          {formatCurrency(price?.total)}
        </PriceValue>
      </ExtraDetailsWrapper>

      <DownloadPDFButton
        hide={!isTablet}
        marginVertical="3rem"
        myMembership={myContactData}
      />
    </>
  );
};

export default TransactionDetailsData;
